import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.header`
  padding: 20px;
  display: flex;
  align-items: center;
`

const MyHead = styled.img`
  height: 40px;
  width: auto;
  margin: 0 20px 0 0;
  vertical-align: -6px;
`

const PageTitle = styled.h1`
  color: white;
  text-transform: uppercase;
  margin: 0;
`

const Header = ({ title: pageTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "me_f1_austin.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <Wrapper>
      <Link to="/">
        <MyHead src={data.placeholderImage.publicURL} alt="" />
      </Link>
      <PageTitle>{pageTitle}</PageTitle>
    </Wrapper>
  )
}

export default Header
