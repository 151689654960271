import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import GridLayout from "../components/gridlayout"

import tiles from "../tiles.json"

const Tiles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-gap: 30px;
  padding: 30px;
`

const Tile = styled.div`
  background: #fff;
  color: #000;
`

const TileContent = styled.div`
  padding: 20px 20px 10px;
`

const projects = () => {
  return (
    <GridLayout title="Projects">
      <Tiles>
        {tiles.map(tile => (
          <Link key={tile.id} to="/" style={{ textDecoration: "none" }}>
            <Tile>
              <div style={{ paddingBottom: "50%", background: "blue" }} />
              <TileContent>
                <h3>{tile.title}</h3>
                <p>{tile.short_desc}</p>
              </TileContent>
            </Tile>
          </Link>
        ))}
      </Tiles>
    </GridLayout>
  )
}

export default projects
