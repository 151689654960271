import React from "react"
import styled from "styled-components"

import Header from "./Header"
import Footer from "./footer"
import SEO from "./seo"

const Grid = styled.div`
  display: grid;
  grid-template-rows: 100px 1fr 70px;
`

const Content = styled.div`
  overflow-y: auto;
`

const gridlayout = ({ children, title }) => {
  return (
    <>
      <SEO title={title} />
      <Grid>
        <Header title={title} />
        <Content>{children}</Content>
        <Footer />
      </Grid>
    </>
  )
}

export default gridlayout
